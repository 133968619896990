import React from 'react'
import Home from './components/Home';
import "./App.css"


function App() {
  return (
    <div style={{ backgroundColor: '#FAF9F6',}}>
      <Home />
    </div>
  );
}


export default App;
